import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader  from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
//import Typography from '@mui/material/Typography';
import Cookies from 'js-cookie';
import NotificationAPI from '../api/Notification';


import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

function Notifications() {
  //const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [notifications, setNotificationsData] = useState([]);


  useEffect(() => {
    const handleResize = async () => {
      //setWindowWidth(window.innerWidth);
    }
    (async () => {
      let csrfToken = Cookies.get('XSRF-TOKEN');
      if (csrfToken == null) window.location.href = process.env.REACT_APP_FULL_DOMAIN + '/';
      await handleResize();
      NotificationAPI.getAll()
        .then(async ({ data }) => {
          let nots_array = []

          data.forEach(element => {
            nots_array.push({
              id: element.id,
              data: JSON.parse(element.data),
              read_at: element.read_at,
              created_at: element.created_at
            })

          });


          setNotificationsData(nots_array)
        }).catch(err => {
            console.log('error on getting notifications:', err)

            return;
        });
    })();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  

  async function handleNotificationRead(not) {
    let data = {
      id: not.id
    }
    NotificationAPI.readNotification(data)
        .then(async () => {

          window.location.reload()

        }).catch(err => {
            console.log('error on reading notification:', err)

            return;
        });
  }



  return (
    <div className="settings-container">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="form-separator" style={{ marginTop: 0 }}>Notificações</div>
            </div>            
            <div className="col-md-12">
            <Timeline position="alternate">
            {
                notifications.map((notification, index) => (
                    <TimelineItem key={index}>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                      <Card elevation={2} sx={{ maxWidth: 500 }}>
                        <CardHeader 
                          title={notification.data.title} 
                          subheader={notification.created_at}
                        />
                        <CardContent>
                          { notification.data.description }
                        </CardContent>
                          {notification.read_at == null &&
                            <CardActions disableSpacing>
                              <IconButton onClick={() => handleNotificationRead(notification) } aria-label="Marcar como lido">
                                <MarkChatReadIcon color={"warning"}/>
                              </IconButton>                              
                            </CardActions>
                          }
                          {notification.read_at != null &&
                            <CardContent className="notification-readed">
                              Lida a {notification.read_at}
                            </CardContent>
                          }
                      </Card>
                      </TimelineContent>
                    </TimelineItem>
                ))
            }
            </Timeline>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notifications;
