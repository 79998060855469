import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export default class BaseApi {
    construct() {}
    
    static getApiUrl() {
        return apiUrl;
    }

    static async startRequest() {
        await BaseApi.getAxiosInstance().get(apiUrl + "sanctum/csrf-cookie");
    }

    static getAxiosInstance(){
        return axios.create({
            withCredentials: true
          });
    }
}