import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import datagridPT from '../datagrid-pt';
import User from '../api/User.js'
import { TailSpin } from 'react-loader-spinner';
import Candidature from '../api/Candidature.js';

function ExportModal({ isOpen, onClose }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [formData, setFormData] = useState({
        date: '',
        date2: '',
    });

    useEffect(() => {
        const handleResize = async () => {
            setWindowWidth(window.innerWidth);
        }
        (async () => {
            await handleResize();
        })();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log('date', value);
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('formData', formData);
        if (formData.date != '' && formData.date2 != '') {
            const dateTimeStr = `${formData.date}T00:00:00`;
            const dateTime = new Date(dateTimeStr);

            const dateTimeStr2 = `${formData.date2}T23:59:59`;
            const dateTime2 = new Date(dateTimeStr2);

            const dataArr = {
                start_date: formatDateToSQL(dateTime),
                end_date: formatDateToSQL(dateTime2),
            }

            User.export(dataArr)
                .then(({ data }) => {
                    console.log('exportResponse', JSON.stringify(data));
                    onClose();
                    window.open(data, '_blank');
                }).catch(err => {
                    if (err.response.status == 422) {
                        console.log('error on exporting:', err.response.data.errors)
                        return;
                    }
                });
        }
    };

    function formatDateToSQL(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>
                    &times;
                </span>
                <form onSubmit={handleSubmit}>
                    <div className="floating-label">
                        <input
                            type="date"
                            id="date"
                            name="date"
                            value={formData.date}
                            onChange={handleChange}
                            placeholder=' '
                            required
                        />
                        <label htmlFor="date" className={formData.date ? 'filled' : ''}>Data de Inicio</label>
                    </div>
                    <div className="floating-label">
                        <input
                            type="date"
                            id="date2"
                            name="date2"
                            value={formData.date2}
                            onChange={handleChange}
                            placeholder=' '
                            required
                        />
                        <label htmlFor="date" className={formData.date2 ? 'filled' : ''}>Data de Fim</label>
                    </div>
                    <div className="modal-footer">
                        <button className="btn-primary" type="submit">EXPORTAR CSV</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ExportModal;
