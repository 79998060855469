import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import User from '../api/User.js';
import Permission from '../api/Permission.js';
import Destiny from '../api/Destiny.js';
import datagridPT from '../datagrid-pt';
import Cookies from 'js-cookie';
import CreateDestinyModal from '../components/createDestinyModal.js';

function Destinies() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [destinyData, setDestinyData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const columnVisibility = {
    id: false,
  }

  const columns = [
    {
      "field": "id",
      "headerName": "ID",
      "hide": true
    },
    {
      "field": "name",
      "headerName": "Nome",
      "flex": 1,
      "editable": false,
    },
    {
      "field": "actions",
      "headerName": "",
      "width": 80,
      "editable": false,
      renderCell: (params) => (
        <div className="table-actions-container">
          <button className="btn-primary-circle btn-delete" onClick={() => deleteDestiny(params.value.destinyId)}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
    },
  ]

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    const handleResize = async () => {
      setWindowWidth(window.innerWidth);
    }
    (async () => {
      let csrfToken = Cookies.get('XSRF-TOKEN');
      if (csrfToken == null) window.location.href=process.env.REACT_APP_FULL_DOMAIN+'/';
      await handleResize();
      await getDestinyList();
    })();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getDestinyList = async () => {
    Destiny.list()
      .then(async ({ data }) => {
        console.log('destinyList', data);
        const transformedData = data.map(destiny => ({
          id: destiny.id,
          name: destiny.destino,
          actions: { destinyId: destiny.id },
        }));
        setDestinyData({
          columns: columns,
          rows: transformedData,
          initialState: { sorting: { sortModel: [{ field: 'id', sort: 'asc' }] } },
          pageSize: 10,
          page: 0,
        });
      }).catch(err => {
        console.log('error on getting destinies list:', err)

        return;
      });
  };

  const handleOpenCreateModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCreateFormSubmit = (formData) => {

    const dataArr = {
      destino: formData.name,
    }

    Destiny.create(dataArr)
      .then(({ data }) => {
        console.log('createDestinyResponse', data);
        handleCloseModal();
        getDestinyList();
      }).catch(err => {
        console.log('error on creating destiny:', err)

        return;
      });
  };

  const deleteDestiny = async (destinyId) => {
    Destiny.delete(destinyId)
      .then(({ data }) => {
        console.log('deleteDestinyResponse', data);
        getDestinyList();
      }).catch(err => {
        console.log('error on deleting destiny:', err)

        return;
      });
  };

  return (
    <div className="settings-container">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="btn-create-appointment">
              <button className="btn-primary" onClick={handleOpenCreateModal}>
                <FontAwesomeIcon icon={faPlus} /> CRIAR DESTINO
              </button>
            </div>
            {destinyData && destinyData.rows.length > 0 &&
              <div className="table-container">
                <DataGrid
                  {...destinyData}
                  localeText={datagridPT}
                  autoHeight
                  initialState={{
                    ...destinyData.initialState,
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  columnVisibilityModel={columnVisibility}
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  disableSelectionOnClick
                />
              </div>
            }
          </div>
        </div>
      </div>
      {isModalOpen && (
        <CreateDestinyModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleCreateFormSubmit}
        />
      )}
    </div>
  );
}

export default Destinies;
