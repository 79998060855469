import React, { useState, useEffect } from 'react';
import Logo from '../resources/logo.png';
import Cookies from 'js-cookie';
import { TailSpin } from 'react-loader-spinner';
import User from '../api/User.js'
import { useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

function VerifyEmail() {
    const [password, setPassword] = useState('');
    const [password_confirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState(null);
    const [isButtonActive, setIsButtonActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const getParams= useParams()


    useEffect(() => {
        
    }, []);

    const goToMicrosoft = async (e) => {
        e.preventDefault()
        Cookies.set('microsoft-id', getParams.id)
        window.location.href = process.env.REACT_APP_FULL_DOMAIN+'/verify-email-microsoft';
    }

    const hadleVerifyEmail = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const encodedPassword = btoa(password);
            const encodedPasswordConfirm = btoa(password_confirm);

            let data = {
                password: encodedPassword,
                password_confirmation: encodedPasswordConfirm,
                id: getParams.id,
                api: null
            }

            User.confirmEmail(data)
                .then(({ data }) => {

                    
                    setSuccess(true);

                }).catch(err => {
                    setIsLoading(false);
                    setError('Dados incorretos');
                    console.log('error on login:', err)

                    return;
                });

        } catch (err) {
            setError('Dados incorretos');
        }
    };    

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        updateButtonState(e.target.value, password_confirm)
    };

    const handlePasswordConfirmChange = (e) => {
        setPasswordConfirm(e.target.value);
        updateButtonState(password, e.target.value)
    };

    const updateButtonState = (password, password_confirm) => {
        setIsButtonActive(password.trim() === password_confirm.trim());
    };

    const handleRedirectHome = async(e) =>  {
        e.preventDefault();
        document.cookie = `XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;
        Cookies.remove('XSRF-TOKEN');
        window.location.href = process.env.REACT_APP_FULL_DOMAIN+'/';
    }


    return (
        <div className="login-container">
            {!success &&
                <div className='login-box'>
                    <img className='login-logo' src={Logo} alt='Conduril' />
                    <form className='login-form' onSubmit={hadleVerifyEmail}>                        
                        <div className="floating-label">
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={handlePasswordChange}
                                placeholder=' '
                                required
                            />
                            <label htmlFor="password" className={password ? 'filled' : ''}>Password</label>
                            {error && <p className="error-message">{error}</p>}
                        </div>
                        <div className="floating-label">
                            <input
                                type="password"
                                id="password_confirm"
                                value={password_confirm}
                                onChange={handlePasswordConfirmChange}
                                placeholder=' '
                                required
                            />
                            <label htmlFor="password_confirm" className={password_confirm ? 'filled' : ''}>Password</label>
                            {error && <p className="error-message">{error}</p>}
                        </div>
                        <button className={`btn-primary ${!isButtonActive ? 'blocked-btn' : ''}`} type="submit">
                            {isLoading && 
                                <TailSpin height="20" width="20" color="#ffffff" ariaLabel="loading" />
                            }
                            {!isLoading &&
                                <span>ALTERAR PASSWORD</span>
                            }
                        </button>
                    </form>             
                    <button className={`btn-microsoft-login btn-margin-top`} onClick={goToMicrosoft}>
                        <span>Registar com Microsoft</span>
                    </button>
                </div>
            }
            {success &&
                <div className='login-box'>
                    <img className='login-logo' src={Logo} alt='Conduril' />  <br/>
                    A sua conta foi registada. Volte para a página de Login para entrar na aplicação<br/>
                    <button className="btn-microsoft-logout btn-margin-top" onClick={handleRedirectHome}><FontAwesomeIcon icon={faArrowCircleLeft} /> Ir para Login</button>
                </div>
            }
        </div>
    );
}

export default VerifyEmail;
