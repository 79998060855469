import BaseApi from "./BaseApi";
import Cookies from 'js-cookie'

export default class Ticket extends BaseApi{
    construct() {}
    
    static async create(data){
        return Ticket.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            console.log("COOKIE");
            console.log(csrfToken);
            
            let url = Ticket.getApiUrl();

            const headers = {
                "Content-Type": "multipart/form-data",
                'X-XSRF-TOKEN': csrfToken,
            };

            return Ticket.getAxiosInstance().post(url, data, {headers});
        });

    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/ticket/";
    }

}