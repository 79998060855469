import React, { useState, useEffect } from 'react';
import Permission from '../api/Permission.js';
import Role from '../api/Role.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

function PermissionsModal({ isOpen, onClose, roleId, roleName, roleDisplayName }) {
    const [myPermissionsList, setMyPermissionsList] = useState([]);
    const [allPermissionsList, setAllPermissionsList] = useState([]);
    const [selectedPermissionIndex, setSelectedPermissionIndex] = useState(null);

    useEffect(() => {
        getPermissionsData();
    }, []);

    const getPermissionsData = async () => {
        const permissionsList = await getPermissionsList();
        await getAllPermissions(permissionsList);
    };

    const getAllPermissions = async (existingPermissions) => {
        try {
            const { data } = await Permission.list();
            const transformedData = data.map(permission => ({
                id: permission.id,
                name: permission.name,
                display_name: permission.display_name
            }));

            const filteredData = transformedData.filter(permission =>
                !existingPermissions.some(existing => existing.id === permission.id)
            );

            console.log('filteredData', filteredData);
            setAllPermissionsList(filteredData);
        } catch (err) {
            console.log('error on getting all permissions list:', err);
        }
    };

    const getPermissionsList = async () => {
        const params = {
            role_id: roleId,
        };

        try {
            const { data } = await Permission.list(params);
            const transformedData = data.map(permission => ({
                id: permission.id,
                name: permission.name,
                display_name: permission.display_name
            }));

            console.log('permissions', transformedData);
            setMyPermissionsList(transformedData);

            return transformedData;
        } catch (err) {
            console.log('error on getting permissions list:', err);
            return [];
        }
    };

    const handleChange = (e) => {
        setSelectedPermissionIndex(e.target.value);
    };

    const addPermission = async () => {
        if (selectedPermissionIndex === null) return;

        const selectedPermission = allPermissionsList[selectedPermissionIndex];
        const updatedPermissionsList = [...myPermissionsList, selectedPermission];

        const dataArr = {
            name: roleName,
            display_name: roleDisplayName,
            permissions: updatedPermissionsList.map(permission => permission.id), // Somente IDs
        };

        try {
            const { data } = await Role.update(roleId, dataArr);
            console.log('updateRoleResponse', data);
            setMyPermissionsList(updatedPermissionsList);
            const newAllPermissionsList = allPermissionsList.filter((_, index) => index !== selectedPermissionIndex);
            setAllPermissionsList(newAllPermissionsList);
            setSelectedPermissionIndex(null);
        } catch (err) {
            console.log('error on updating role:', err);
        }
    };

    const removePermission = async (permissionId) => {
        const updatedPermissionsList = myPermissionsList.filter(permission => permission.id !== permissionId);

        const dataArr = {
            name: roleName,
            display_name: roleDisplayName,
            permissions: updatedPermissionsList.map(permission => permission.id), // Somente IDs
        };

        try {
            const { data } = await Role.update(roleId, dataArr);
            console.log('updateRoleResponse', data);
            setMyPermissionsList(updatedPermissionsList);
            const removedPermission = myPermissionsList.find(permission => permission.id === permissionId);
            setAllPermissionsList([...allPermissionsList, removedPermission]);
        } catch (err) {
            console.log('error on updating role:', err);
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>
                    &times;
                </span>
                <div className="permission-list">
                    {myPermissionsList.map((permission, i) => (
                        <div className="permission-row" key={i}>
                            <p>{permission.display_name}</p>
                            <button className="btn-primary-circle btn-delete" onClick={() => removePermission(permission.id)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </div>
                    ))}
                </div>
                <div className="permission-add">
                    <div className="floating-label">
                        <select
                            id="role"
                            name="role"
                            value={selectedPermissionIndex || ''}
                            onChange={handleChange}
                        >
                            <option value="" disabled>Select a permission</option>
                            {allPermissionsList.map((permission, i) => (
                                <option key={i} value={i}>{permission.display_name}</option>
                            ))}
                        </select>
                        <label htmlFor="role" className="filled select-label">Role</label>
                    </div>
                    <button className="btn-primary-circle" onClick={addPermission}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PermissionsModal;
