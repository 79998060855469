import { initializeApp } from 'firebase/app';
import { getMessaging, getToken as getFCMToken, onMessage } from 'firebase/messaging';

// Configurações do seu projeto no Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Solicitar permissão para notificações
export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      console.log('Permissão para notificações concedida.');
    } else {
      console.warn('Permissão para notificações não concedida.');
    }
  } catch (error) {
    console.error('Erro ao solicitar permissão para notificações', error);
  }
};

// Obter token FCM
export const getToken = async () => {
  try {
    const currentToken = await getFCMToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY });
    if (currentToken) {
      console.log('Token FCM:', currentToken);
      // Envie este token para o backend para associá-lo ao usuário
      return currentToken;
    } else {
      console.warn('Nenhum token FCM disponível.');
    }
  } catch (error) {
    console.error('Erro ao obter token FCM', error);
  }
};

onMessage(messaging, (payload) => {
  console.log('Mensagem recebida em primeiro plano: ', payload);

  if (navigator.serviceWorker) {
    console.log('ServiceWorker is supported');
    
    navigator.serviceWorker.ready.then((registration) => {
      console.log('ServiceWorker is ready');
      if (registration.active) {
        console.log('ServiceWorker is active');
        console.log('Enviando payload para o ServiceWorker:', payload);
        registration.active.postMessage(payload);
      } else {
        console.error('ServiceWorker not active.');
      }
    }).catch((error) => {
      console.error('Erro ao acessar o ServiceWorker:', error);
    });
  } else {
    console.error('ServiceWorker not supported.');
  }
});


export { messaging };
