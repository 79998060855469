import React, { useState } from 'react';

function CreateDestinyModal({ isOpen, onClose, onSubmit }) {
    const [formData, setFormData] = useState({
        name: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('formData', formData);
        if (formData.name != '') {
            onSubmit(formData);
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>
                    &times;
                </span>
                <form onSubmit={handleSubmit}>
                    <div className="floating-label">
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder=' '
                            required
                        />
                        <label htmlFor="name" className={formData.name ? 'filled' : ''}>Nome</label>
                    </div>
                    <input 
                        type="hidden" 
                        id="userId"
                        name="userId"
                        value={formData.userId}
                    />
                    <div className="modal-footer">
                        <button className="btn-primary" type="submit">CRIAR DESTINO</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateDestinyModal;
