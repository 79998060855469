import React, { useState, useEffect } from 'react';
import Logo from '../resources/logo.png';
import { TailSpin } from 'react-loader-spinner';
import User from '../api/User.js'

function VerifyEmail() {
    const [username, setUsername] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonActive, setIsButtonActive] = useState(false);
    const [success, setSuccess] = useState(false);


    useEffect(() => {
        
    }, []);

   const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let data = {
        username: username
    }
    User.resendActivationEmail(data).then((resp)    =>  {
        setSuccess(true)
    })
   }
    
    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
        updateButtonState(e.target.value);
    };

    const updateButtonState = (newUsername) => {
        setIsButtonActive(newUsername.trim() !== '' && newUsername.includes('@'));
    };


    return (
        <div className="login-container">
            {
                <div className='login-box'>
                    <img className='login-logo' src={Logo} alt='Conduril' />
                    {!success &&
                    <form className='login-form' onSubmit={handleResetPassword}>                        
                        <div className="floating-label">
                            <input
                                type="email"
                                id="email"
                                value={username}
                                onChange={handleUsernameChange}
                                placeholder=' '
                                required
                            />
                            <label htmlFor="email" className={username ? 'filled' : ''}>Email</label>
                        </div>
                        <button className={`btn-primary ${!isButtonActive ? 'blocked-btn' : ''}`} type="submit">
                            {isLoading && 
                                <TailSpin height="20" width="20" color="#ffffff" ariaLabel="loading" />
                            }
                            {!isLoading &&
                                <span>RECUPERAR</span>
                            }
                        </button>
                    </form>      
                }
                {success &&
                    <div>Mensagem enviada. Por favor verifique o seu email!</div>
                }
                </div>
            }
        </div>
    );
}

export default VerifyEmail;
