import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Destiny from '../api/Destiny.js';
import Car from '../api/Car.js';
import { TailSpin } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

function CreateTripModal({ isOpen, onClose, onSubmit }) {
    const [formData, setFormData] = useState({
        date: '',
        time: '',
        date2: '',
        time2: '',
        departure: '',
        destination: '',
        seats: '',
        km: '',
    });
    const [destiniesList, setDestiniesList] = useState(null);
    const [hasCar, setHasCar] = useState(null);

    useEffect(() => {
        (async () => {
            await getDestiniesList();
            Car.get(localStorage.getItem("userID"))
                .then(async ({ data }) => {
                    setHasCar(true);
                }).catch(err => {
                    setHasCar(false);
                    return;
                });
        })();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log('date', value);
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('formData', formData);
        if (formData.date != '' && formData.time != '' && formData.date2 != '' && formData.time2 != '' && formData.departure != '' && formData.destination != '' && formData.seats != '' && formData.km != '') {
            onSubmit(formData);
        }
    };

    const getDestiniesList = async () => {
        Destiny.list()
            .then(async ({ data }) => {
                console.log('destinyList', data);
                const transformedData = data.map(destiny => ({
                    id: destiny.id,
                    name: destiny.destino,
                }));
                setDestiniesList(transformedData);
                setFormData({
                    ...formData,
                    ["departure"]: transformedData[0].name,
                    ["destination"]: transformedData[1].name,
                });
            }).catch(err => {
                console.log('error on getting desttinies list:', err)

                return;
            });
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>
                    &times;
                </span>
                {destiniesList == null && hasCar == null &&
                    <div className="loading-container">
                        <TailSpin height="80" width="80" color="#0c1534" ariaLabel="loading" />
                    </div>
                }
                {destiniesList != null && hasCar != null &&
                    <form onSubmit={handleSubmit}>
                        {hasCar &&
                            <>
                                <div className="floating-label">
                                    <input
                                        type="date"
                                        id="date"
                                        name="date"
                                        value={formData.date}
                                        onChange={handleChange}
                                        placeholder=' '
                                        required
                                    />
                                    <label htmlFor="date" className={formData.date ? 'filled' : ''}>Data de Partida</label>
                                </div>
                                <div className="floating-label">
                                    <input
                                        type="time"
                                        id="time"
                                        name="time"
                                        value={formData.time}
                                        onChange={handleChange}
                                        placeholder=' '
                                        required
                                    />
                                    <label htmlFor="time" className={formData.time ? 'filled' : ''}>Hora de Partida</label>
                                </div>
                                <div className="floating-label">
                                    <input
                                        type="date"
                                        id="date2"
                                        name="date2"
                                        value={formData.date2}
                                        onChange={handleChange}
                                        placeholder=' '
                                        required
                                    />
                                    <label htmlFor="date" className={formData.date2 ? 'filled' : ''}>Data de Chegada</label>
                                </div>
                                <div className="floating-label">
                                    <input
                                        type="time"
                                        id="time2"
                                        name="time2"
                                        value={formData.time2}
                                        onChange={handleChange}
                                        placeholder=' '
                                        required
                                    />
                                    <label htmlFor="time" className={formData.time2 ? 'filled' : ''}>Hora de Chegada</label>
                                </div>
                                <div className="floating-label">
                                    <select
                                        id="departure"
                                        name="departure"
                                        defaultValue={destiniesList[0].name}
                                        onChange={handleChange}
                                    >
                                        {destiniesList.map((destiny, i) => {
                                            return (
                                                <option key={i} value={destiny.name}>{destiny.name}</option>
                                            )
                                        })}
                                    </select>
                                    <label htmlFor="departure" className="filled select-label">Partida</label>
                                </div>
                                <div className="floating-label">
                                    <select
                                        id="destination"
                                        name="destination"
                                        defaultValue={destiniesList[1].name}
                                        onChange={handleChange}
                                    >
                                        {destiniesList.map((destiny, i) => {
                                            return (
                                                <option key={i} value={destiny.name}>{destiny.name}</option>
                                            )
                                        })}
                                    </select>
                                    <label htmlFor="destination" className="filled select-label">Destino</label>
                                </div>
                                <div className="floating-label">
                                    <input
                                        type="number"
                                        id="seats"
                                        name="seats"
                                        value={formData.seats}
                                        onChange={handleChange}
                                        placeholder=' '
                                        required
                                    />
                                    <label htmlFor="seats" className={formData.seats ? 'seats' : ''}>Lugares Disponíveis</label>
                                </div>
                                <div className="floating-label">
                                    <input
                                        type="number"
                                        id="km"
                                        name="km"
                                        value={formData.km}
                                        onChange={handleChange}
                                        placeholder=' '
                                        required
                                    />
                                    <label htmlFor="km" className={formData.km ? 'km' : ''}>KMs</label>
                                </div>
                            </>
                        }
                        {!hasCar &&
                            <div>
                                <FontAwesomeIcon icon={faTriangleExclamation} className="warning-icon"/>
                                <p className="error-message2">Antes de criar uma viagem adicione um carro ao seu perfil <Link to={'/settings'}>aqui</Link></p>
                            </div>
                        }
                        {hasCar &&
                            <div className="modal-footer">
                                <button className="btn-primary" type="submit">MARCAR VIAGEM</button>
                            </div>
                        }
                    </form>
                }
            </div>
        </div>
    );
}

export default CreateTripModal;
