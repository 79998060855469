import BaseApi from "./BaseApi";
import Cookies from 'js-cookie';

export default class Car extends BaseApi{
    construct() {}
    
    static async create(data) {
        return Car.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Car.getApiUrl();

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Car.getAxiosInstance().post(url, data, {headers});
        });
    }

    static async update(carId, data) {
        return Car.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Car.getApiUrl() + carId;

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Car.getAxiosInstance().put(url, data, {headers});
        });
    }

    static async get(id) {
        return Car.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Car.getApiUrl() + id;

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Car.getAxiosInstance().get(url, {headers});
        });
    }

    static async delete(carId) {
        return Car.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Car.getApiUrl() + carId;

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Car.getAxiosInstance().delete(url, {headers});
        });
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/cars/";
    }
}