import React from 'react';

const InformationDialog = ({ isOpen, message, onOk }) => {
  if (!isOpen) return null;

  return (
    <div className="confirm-dialog-overlay">
      <div className="confirm-dialog">
        <p>{message}</p>
        <div className="confirm-dialog-buttons"  style={{ justifyContent: 'center' }}>
            <button className="btn-primary btn-center" onClick={onOk}>Ok</button>
        </div>
      </div>
    </div>
  );
};

export default InformationDialog;
