import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../resources/logo.png';
import Cookies from 'js-cookie';
import { TailSpin } from 'react-loader-spinner';
import User from '../api/User.js'
import { useParams } from 'react-router';
import { useMsal } from "@azure/msal-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouseLock, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';

function VerifyEmailMicrosoft() {
    const [password, setPassword] = useState('');
    const [password_confirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState(null);
    const [isButtonActive, setIsButtonActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { instance, accounts, inProgress } = useMsal();
    const [success, setSuccess] = useState(false);
    const isAuthenticated = accounts.length > 0;



   
    const handleVerifyAccount = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {

            let password = accounts[0]['idTokenClaims']['oid']

            let email = accounts[0]['username']

            let data = {
                id: Cookies.get('microsoft-id'),
                password: password,
                email: email,
                api: 'microsoft',
            }

            User.confirmEmail(data)
                .then(({ data }) => {
                    setSuccess(true);
                }).catch(err => {
                    setIsLoading(false);
                    setError('Dados incorretos');
                    console.log('error on login:', err)

                    return;
                });

        } catch (err) {
            setError('Dados incorretos');
        }
    };    

    const handleRedirectHome = async(e) =>  {
        e.preventDefault();
        document.cookie = `XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;
        Cookies.remove('XSRF-TOKEN');
        window.location.href = process.env.REACT_APP_FULL_DOMAIN+'/';
    }




    return (
        <div className="login-container">
            {!success &&
                <div className='login-box'>
                    <img className='login-logo' src={Logo} alt='Conduril' />                 
                        {!isAuthenticated &&
                            <button className="btn-microsoft-login btn-margin-top" onClick={() => instance.loginPopup()}><FontAwesomeIcon icon={faHouseLock} /> Login com Microsoft</button>
                        }
                        {isAuthenticated &&
                        <form onSubmit={handleVerifyAccount}>
                            <div className="div-microsoft-title">
                                Microsoft
                            </div>
                            <button className="btn-microsoft-login" type="submit"><FontAwesomeIcon icon={faHouseLock} /> {accounts[0]['username']}</button>	
                        </form>
                        }
                        {isAuthenticated &&
                            <button className="btn-microsoft-logout btn-margin-top" onClick={() => instance.logout()}><FontAwesomeIcon icon={faArrowCircleLeft} /> Logout</button>
                        }    
                </div>
            }
            {success &&
                <div className='login-box'>
                    <img className='login-logo' src={Logo} alt='Conduril' />  <br/>
                    A sua conta foi registada. Volte para a página de Login para entrar na aplicação<br/>
                    <button className="btn-microsoft-logout btn-margin-top" onClick={handleRedirectHome}><FontAwesomeIcon icon={faArrowCircleLeft} /> Ir para Login</button>
                </div>
            }
        </div>
    );
}

export default VerifyEmailMicrosoft;
