import BaseApi from "./BaseApi";
import Cookies from 'js-cookie'

export default class Permission extends BaseApi{
    construct() {}

    static async list(params) {
        let csrfToken = Cookies.get('XSRF-TOKEN')
        let url = Permission.getApiUrl() + "list";

        const headers = {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': csrfToken,
        };

         return Permission.getAxiosInstance().get(url, {params: params}, {headers});
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/permissions/";
    }
}