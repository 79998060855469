import BaseApi from "./BaseApi";
import Cookies from 'js-cookie';

export default class Candidature extends BaseApi{
    construct() {}
    
    static async create(tripId, valid = 0) {
        return Candidature.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Candidature.getApiUrl();

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            const data = {
                trip_id: tripId,
                valid: valid
            }

            return Candidature.getAxiosInstance().post(url, data, {headers});
        });
    }

    static async delete(tripId) {
        return Candidature.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Candidature.getApiUrl() + tripId;

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Candidature.getAxiosInstance().delete(url, {headers});
        });
    }

    static async get(tripId) {
        return Candidature.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Candidature.getApiUrl() + tripId;

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Candidature.getAxiosInstance().get(url, {headers});
        });
    }

    static async validate(id) {
        return Candidature.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Candidature.getApiUrl() + 'validate/' + id;

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Candidature.getAxiosInstance().put(url, [], {headers});
        });
    }

    static async reject(id) {
        return Candidature.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Candidature.getApiUrl() + 'reject/' + id;

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Candidature.getAxiosInstance().put(url, [], {headers});
        });
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/candidature/";
    }
}