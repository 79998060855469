import BaseApi from "./BaseApi";
import Cookies from 'js-cookie'

export default class User extends BaseApi{
    construct() {}
    
    static async login(username, password, api) {
        return User.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')
            let url = BaseApi.getApiUrl() + "login";

            let data = {
                username: username,
                password: password,
                api: api
            }

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return User.getAxiosInstance().post(url, data, {headers});
        });
    }

    static async getSession() {
        let csrfToken = Cookies.get('XSRF-TOKEN')
        let url = User.getApiUrl() + "session";

        const headers = {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': csrfToken,
        };

        return User.getAxiosInstance().get(url, {headers});
    }

    static async logout() {
        let csrfToken = Cookies.get('XSRF-TOKEN')
        let url = BaseApi.getApiUrl() + "logout";

        const headers = {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': csrfToken,
        };

        return User.getAxiosInstance().post(url, {}, {headers});
    }

    static async create(data) {
        let csrfToken = Cookies.get('XSRF-TOKEN');
        let url = User.getApiUrl();

        const headers = {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': csrfToken,
        };

        return User.getAxiosInstance().post(url, data, {headers});
    }

    static async update(userId, data) {
        let csrfToken = Cookies.get('XSRF-TOKEN');
        let url = User.getApiUrl() + userId;

        const headers = {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': csrfToken,
        };

        return User.getAxiosInstance().put(url, data, {headers});
    }

    static async removeMicrosoftAccount(userId){
        let csrfToken = Cookies.get('XSRF-TOKEN');
        let url = User.getApiUrl() + 'remove-microsoft-account';

        const headers = {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': csrfToken,
        };

        let data = {
            user_id: userId
        }
        
        return User.getAxiosInstance().post(url, data, {headers});
    }

    static async get(id) {
        return User.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = User.getApiUrl() + id;

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return User.getAxiosInstance().get(url, {headers});
        });
    }

    static async reactivate(userId){
        let csrfToken = Cookies.get('XSRF-TOKEN');
        let url = User.getApiUrl() + 'reactivate/' + userId;

        const headers = {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': csrfToken,
        };

        let data = {
            user_id: userId
        }
        
        return User.getAxiosInstance().post(url, data, {headers});
    }
    

    static async delete(userId) {
        return User.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = User.getApiUrl() + userId;

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return User.getAxiosInstance().delete(url, {headers});
        });
    }

    static async export(data) {
        return User.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = User.getApiUrl() + 'export';

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return User.getAxiosInstance().post(url, data, {headers});
        });
    }

    // Microsoft login
    static async microsoftLogin(username, oid) {
        return User.startRequest().then(() => {
            let url = BaseApi.getApiUrl() + "login";

            let data = {
                username: username,
                password: oid,
                api: 'microsoft'
            }

            return User.getAxiosInstance().post(url, data);
        });
    }

    static async registerExternalLogin(username, oid, platform, id) {
        let url = User.getApiUrl() + "register/" + platform + "/" + id;

        let data = {
            username: username,
            password: oid,
            api: platform
        }

        return User.getAxiosInstance().post(url, data);
    }

    static async login2Fa(code) {
        let url = BaseApi.getApiUrl() + "two-factor-challenge";

        return User.getAxiosInstance().post(url, {code: code});
    }

    static async confirmEmail(params) {
        let csrfToken = Cookies.get('XSRF-TOKEN')
        const headers = {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': csrfToken,
        };
        return User.startRequest().then(() => {

            let url = User.getApiUrl() + (params.api == null ? "verify-email" : "verify-email-microsoft");

            
            return User.getAxiosInstance().post(url, params, {headers});
        });
    }

    static async updatePassword(params) {
        return User.startRequest().then(() => {
            let url = User.getApiUrl() + "password";

            return User.getAxiosInstance().put(url, params);
        });
    }

    static async reset(email) {
        let url = User.getApiUrl() + "reset";

        return User.getAxiosInstance().post(url, {email: email});
    }

    static async list(params) {
        let url = User.getApiUrl() + "list";

        return User.getAxiosInstance().get(url,{params: params});
    }

    static async search(params) {
        return await User.getAxiosInstance().get(User.getApiUrl(),{params: params});
    }

    static async resendActivationEmail(params) {
        return User.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')
            let url = BaseApi.getApiUrl() + "email-verification";

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return User.getAxiosInstance().post(url, params, {headers});
        });
    }



    static async updateActivation(user) {
        let params = {
            name: user.name,
            email: user.email,
            username: user.username,
            phone_number: user.phone_number,
            is_active: user.is_active,
            roles: user.roles.map((item) => {return item.id}),
        };

        return await User.getAxiosInstance().put(User.getApiUrl() + user.id,params);
    }

    static async find(id) {
        return await User.getAxiosInstance().get(User.getApiUrl() + id);
    }

    static async enable2FA() {
        return await User.getAxiosInstance().post(BaseApi.getApiUrl() + "user/two-factor-authentication");
    }

    static async disable2FA() {
        return await User.getAxiosInstance().delete(BaseApi.getApiUrl() + "user/two-factor-authentication");
    }

    static async get2FA() {
        return await User.getAxiosInstance().get(BaseApi.getApiUrl() + "user/two-factor-qr-code");
    }

    static getInstance(data) {
        let user = new User(data);

        Object.assign(user, data);

        user.permissions = user.permissions.map((item) => {
            return item.name;
        });

        user.roles = user.roles.map((item) => {
            return item.name;
        });

        return user;
    }

    hasPermission(permissions = []) {

        return this.permissions.some(permission => permissions.indexOf(permission) >= 0);
    }

    hasRole(roles = []) {

        return this.roles.some(role => roles.indexOf(role) >= 0);
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/users/";
    }
}