import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFileExport } from '@fortawesome/free-solid-svg-icons';
import TripDataGrid from '../components/tripDataGrid';
import CreateTripModal from '../components/createTripModal';
import DetailsModal from '../components/detailsModal';
import InformationDialog from '../components/informationDialog'
import ConfirmDialog from '../components/confirmDialog';
import Trip from '../api/Trip.js';
import User from '../api/User.js';
import Notification from '../api/Notification.js';
import Candidature from '../api/Candidature.js';
import { SessionContext } from '../SessionContext';
import Cookies from 'js-cookie';

function Trips() {
  const { isAuthenticated, session, sessionLoaded } = useContext(SessionContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [confirmLeaveDialogOpen, setConfirmLeaveDialogOpen] = useState(false);
  const [confirmJoinDialogOpen, setConfirmJoinDialogOpen] = useState(false);
  const [confirmInformationDialogOpen, setConfirmInformationDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [leaveId, setLeaveId] = useState(0);
  const [joinId, setJoinId] = useState(0);
  const [selectedTripId, setSelectedTripId] = useState(0);
  const [tripData, setTripData] = useState({
    rows: [],
    initialState: { sorting: { sortModel: [{ field: 'date', sort: 'desc' }] } },
    rowCount: 0,
    pageSize: 10,
    page: 0,
  });

  const handleRowClick = (params) => {
    setSelectedTripId(params.row.id);
    handleOpenDetailsModal();
  };

  useEffect(() => {
    (async () => {
      if (!sessionLoaded) {
        return; // Não faz nada até que a sessão seja carregada
      }

      if (isAuthenticated) {
        await getTrips();
      }
      else {
        window.location.href = process.env.REACT_APP_FULL_DOMAIN + '/';
      }
    })();
  }, []);

  const getTrips = async () => {
    const params = {
      future: true,
    };
    Trip.search(params)
      .then(({ data }) => {
        const transformedData = data.data.map(trip => ({
          id: trip.id,
          departure: trip.partida,
          destination: trip.destino,
          date: {
            day: new Date(trip.hora_saida).getDate(),
            month: new Date(trip.hora_saida).getMonth() + 1,
            year: new Date(trip.hora_saida).getFullYear(),
            time: new Date(trip.hora_saida).toLocaleTimeString('pt-PT', { hour: '2-digit', minute: '2-digit' }),
            day2: new Date(trip.hora_chegada).getDate(),
            month2: new Date(trip.hora_chegada).getMonth() + 1,
            year2: new Date(trip.hora_chegada).getFullYear(),
            time2: new Date(trip.hora_chegada).toLocaleTimeString('pt-PT', { hour: '2-digit', minute: '2-digit' }),
            all_confirmed: trip.all_confirmed,
            creator: trip.user_id
          },
          actions: { seats: trip.trip_available_seats, km: trip.km_viagem, id: trip.id, registered: trip.belongs_to_trip, creator: trip.user_id, valid: trip.valid, all_confirmed: trip.all_confirmed, rejected: trip.rejected },
          registered: trip.belongs_to_trip,
        }));

        setTripData(prevState => ({
          ...prevState,
          rows: transformedData,
          rowCount: data.total, // Supondo que a API retorna o total de registros
        }));
      }).catch(err => {
        if (err.response.status === 422) {
          console.log('error on listing trips:', err.response.data.errors);
        }
      });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const openInformation = () => {
    setConfirmInformationDialogOpen(true)
  }

  const handleFormSubmit = (formData) => {
    const dateTimeStr = `${formData.date}T${formData.time}:00`;
    const dateTime = new Date(dateTimeStr);

    const dateTime2Str = `${formData.date2}T${formData.time2}:00`;
    const dateTime2 = new Date(dateTime2Str);

    const dataArr = {
      user_id: localStorage.getItem('userID'),
      partida: formData.departure,
      destino: formData.destination,
      lugares_disponiveis: formData.seats,
      hora_saida: formatDateToSQL(dateTime),
      hora_chegada: formatDateToSQL(dateTime2),
      km_viagem: formData.km
    };

    Trip.create(dataArr)
      .then(({ data }) => {
        const title = data.title;
        const texto = data.texto;
        const tokens = data.tokens;
        console.log(data.data);
        Candidature.create(data.data.id)
          .then(({ data }) => {
            console.log('createCandidatureResponse', JSON.stringify(data));
            Notification.send(title, texto, tokens);
            handleCloseModal();
            getTrips();
          }).catch(err => {

            if (err.response.status == 422) {

              console.log('error on creating candidature:', err.response.data.errors)

              return;
            }
          });
      }).catch(err => {
        if (err.response.status == 422) {
          console.log('error on creating trip:', err.response.data.errors);
          return;
        }
      });
  };

  function formatDateToSQL(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const handleOpenDetailsModal = () => {
    setIsDetailsModalOpen(true);
  };

  const handleCloseDetailsModal = () => {
    setIsDetailsModalOpen(false);
  };

  const joinTripDialog = (id, event) => {
    event.stopPropagation();
    setConfirmJoinDialogOpen(true);
    setJoinId(id);
  };

  const joinTrip = (event) => {
    event.stopPropagation();
    Candidature.create(joinId)
      .then(({ data }) => {
        Notification.send(data.title, data.texto, data.tokens);
        getTrips();
        setConfirmJoinDialogOpen(false);
      }).catch(err => {
        if (err.response.status == 422) {
          console.log('error on creating candidature:', err.response.data.errors);
          return;
        }
      });
  };

  const leaveTripDialog = (id, event) => {
    event.stopPropagation();
    setConfirmLeaveDialogOpen(true);
    setLeaveId(id);
  };

  const leaveTrip = (event) => {
    event.stopPropagation();
    Candidature.delete(leaveId)
      .then(({ data }) => {
        Notification.send(data.title, data.texto, data.tokens);
        getTrips();
        setConfirmLeaveDialogOpen(false);
      }).catch(err => {
        if (err.response.status == 422) {
          console.log('error on deleting candidature:', err.response.data.errors);
          return;
        }
      });
  };

  const deleteTripDialog = (id, event) => {
    event.stopPropagation();
    setConfirmDeleteDialogOpen(true);
    setDeleteId(id);
  };

  const deleteTrip = (event) => {
    event.stopPropagation();
    Trip.delete(deleteId)
      .then(({ data }) => {
        Notification.send(data.title, data.texto, data.tokens);
        getTrips();
        setConfirmDeleteDialogOpen(false);
      }).catch(err => {
        if (err.response.status == 422) {
          console.log('error on deleting candidature:', err.response.data.errors);
          return;
        }
      });
  };

  return (
    <div className="trips-container">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="btn-create-appointment">
              {session && (session.permissions.some(permission => permission.name === "trips.create") || session.permissions.some(permission => permission.name === "super")) &&
                <button className="btn-primary" onClick={handleOpenModal}>
                  <FontAwesomeIcon icon={faPlus} /> MARCAR VIAGEM
                </button>
              }
            </div>
            <div className="table-container">
              <TripDataGrid
                data={tripData}
                onRowClick={handleRowClick}
                joinTrip={joinTripDialog}
                leaveTrip={leaveTripDialog}
                deleteTrip={deleteTripDialog}
                refreshTrips={getTrips}
                openInformDialog={openInformation}
              />
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <CreateTripModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSubmit={handleFormSubmit}
        />
      )}
      {isDetailsModalOpen && (
        <DetailsModal
          isOpen={isDetailsModalOpen}
          onClose={handleCloseDetailsModal}
          tripId={selectedTripId}
          fatherWindow={'trips'}
        />
      )}
      <ConfirmDialog
        isOpen={confirmDeleteDialogOpen}
        message="Tem certeza que deseja eliminar esta viagem?"
        onConfirm={deleteTrip}
        onCancel={() => setConfirmDeleteDialogOpen(false)}
      />
      <ConfirmDialog
        isOpen={confirmLeaveDialogOpen}
        message="Tem certeza que deseja abandonar esta viagem?"
        onConfirm={leaveTrip}
        onCancel={() => setConfirmLeaveDialogOpen(false)}
      />
      <ConfirmDialog
        isOpen={confirmJoinDialogOpen}
        message="Tem certeza que deseja juntar-se a esta viagem?"
        onConfirm={joinTrip}
        onCancel={() => setConfirmJoinDialogOpen(false)}
      />       
      <InformationDialog
        isOpen={confirmInformationDialogOpen}
        message="Para confirmar a viagem é necessário confirmar os utilizadores da mesma"
        onOk={() => setConfirmInformationDialogOpen(false)}
      />
    </div>
  );
}

export default Trips;
