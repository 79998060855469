import BaseApi from "./BaseApi";
import Cookies from 'js-cookie';

export default class Destiny extends BaseApi{
    construct() {}
    
    static async create(data) {
        return Destiny.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Destiny.getApiUrl();

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Destiny.getAxiosInstance().post(url, data, {headers});
        });
    }

    static async list() {
        return Destiny.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Destiny.getApiUrl() + 'list';

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Destiny.getAxiosInstance().get(url, {headers});
        });
    }

    static async delete(destinyId) {
        return Destiny.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Destiny.getApiUrl() + destinyId;

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Destiny.getAxiosInstance().delete(url, {headers});
        });
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/destinies/";
    }
}