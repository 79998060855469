import BaseApi from "./BaseApi";
import Cookies from 'js-cookie';

export default class Trip extends BaseApi{
    construct() {}
    
    static async create(data) {
        return Trip.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN');

            let url = Trip.getApiUrl();

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Trip.getAxiosInstance().post(url, data, { headers });
        });
    }

    static async list() {
        return Trip.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Trip.getApiUrl() + 'list';

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Trip.getAxiosInstance().get(url, {headers});
        });
    }

    static async search(params) {
        return Trip.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')
            let url = Trip.getApiUrl();

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Trip.getAxiosInstance().get(url, {params: params}, {headers});
        });
    }

    static async km() {
        return Trip.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Trip.getApiUrl() + 'km';

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Trip.getAxiosInstance().get(url, {headers});
        });
    }

    static async lastCandidature() {
        return Trip.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Trip.getApiUrl() + 'last-candidature';

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Trip.getAxiosInstance().get(url, {headers});
        });
    }

    static async get(id) {
        return Trip.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Trip.getApiUrl() + id;

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Trip.getAxiosInstance().get(url, {headers});
        });
    }

    static async update(tripId, data) {
        return Trip.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Trip.getApiUrl() + tripId;

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Trip.getAxiosInstance().put(url, data, {headers});
        });
    }

    static async validate(tripId) {
        return Trip.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Trip.getApiUrl() + 'validate/' + tripId;

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Trip.getAxiosInstance().put(url, [], {headers});
        });
    }

    static async delete(tripId) {
        return Trip.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Trip.getApiUrl() + tripId;

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Trip.getAxiosInstance().delete(url, {headers});
        });
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/trips/";
    }
}