import BaseApi from "./BaseApi";
import Cookies from 'js-cookie';

export default class Notification extends BaseApi {
    construct() {}

    static async send(title, texto, tokens) {
        return Notification.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN');

            const encodedTitle = encodeURIComponent(title);
            const encodedTexto = encodeURIComponent(texto);
            const encodedTokens = encodeURIComponent(JSON.stringify(tokens));

            const url = Notification.getApiUrl() + `create-notification?title=${encodedTitle}&texto=${encodedTexto}&tokens=${encodedTokens}`;

            // Criar um iframe para carregar a URL
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none'; // Opcional: esconder o iframe
            iframe.src = url;
            document.body.appendChild(iframe);
        });
    }

    static async getAll() {
        return Notification.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Notification.getBAckendApiUrl();

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Notification.getAxiosInstance().get(url, {headers});
        });
    }

    static async readNotification(data) {
        return Notification.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Notification.getBAckendApiUrl() + '/read';

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Notification.getAxiosInstance().post(url, data, {headers});
        });
    }

    static getBAckendApiUrl() {
        return BaseApi.getApiUrl() + 'api/admin/notifications'
    }

    static getApiUrl() {
        return BaseApi.getApiUrl()
    }
}
