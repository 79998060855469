import BaseApi from "./BaseApi";
import Cookies from 'js-cookie'

export default class Role extends BaseApi{
    construct() {}

    static async list() {
        let csrfToken = Cookies.get('XSRF-TOKEN')
        let url = Role.getApiUrl() + "list";

        const headers = {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': csrfToken,
        };

        return Role.getAxiosInstance().get(url, {headers});
    }

    static async create(data) {
        return Role.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Role.getApiUrl();

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Role.getAxiosInstance().post(url, data, {headers});
        });
    }

    static async update(roleId, data) {
        return Role.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Role.getApiUrl() + roleId;

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Role.getAxiosInstance().put(url, data, {headers});
        });
    }

    static async delete(roleId) {
        return Role.startRequest().then(() => {
            let csrfToken = Cookies.get('XSRF-TOKEN')

            let url = Role.getApiUrl() + roleId;

            const headers = {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            };

            return Role.getAxiosInstance().delete(url, {headers});
        });
    }

    static getApiUrl() {
        return BaseApi.getApiUrl() + "api/admin/roles/";
    }
}