import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import datagridPT from '../datagrid-pt';
import Trip from '../api/Trip.js';
import { TailSpin } from 'react-loader-spinner';
import Candidature from '../api/Candidature.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCrown, faRemove } from '@fortawesome/free-solid-svg-icons';

function DetailsModal({ isOpen, onClose, tripId, fatherWindow }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [tripDetails, setTripDetails] = useState(null);
    const [tripUsers, setTripUsers] = useState({ columns: [], rows: [], initialState: { sorting: { sortModel: [{ field: 'id', sort: 'asc' }] } } });

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        const fetchData = async () => {
            await getTripDetails(); // Load trip details first
        };

        fetchData(); // Call fetchData to load trip details
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (tripDetails) {
            getTripUsers(); // Load trip users only after trip details are available
        }
    }, [tripDetails]); // Depend on tripDetails to fetch users

    const columnVisibility = {
        id: false,
        actions: fatherWindow === 'dashboard' ? false : true
    };

    const columns = [
        {
            "field": "id",
            "headerName": "ID",
            "hide": true
        },
        {
            "field": "user",
            "headerName": "Passageiros",
            "flex": 1,
            "editable": false,
        },
        {
            "field": "phone_number",
            "headerName": "Telefone",
            "flex": 1,
            "editable": false,
        },
        {
            "field": "actions",
            "headerName": "",
            "disableColumnMenu": true,
            "editable": false,
            renderCell: (params) => (
                <div className="table-date-container">
                    <div>
                        {tripDetails && new Date(tripDetails.hora_saida) < new Date() && params.value.user_id != localStorage.getItem("userID") && params.value.valid === 0 && params.value.rejected === 0 &&
                            <button
                                className={"btn-primary-circle " + (params.value.valid === 1 ? "btn-valid" : 'btn-pending-valid')}
                                onClick={(event) => validateUser(event, params.id, params.value.user_id)}
                            >
                                <FontAwesomeIcon icon={faCheck} />
                            </button>
                        }
                        {params.value.valid === 1 && params.value.user_id != localStorage.getItem("userID") &&
                            <FontAwesomeIcon icon={faCheck} />
                        }
                        {params.value.rejected === 1 && params.value.user_id != localStorage.getItem("userID") &&
                            <FontAwesomeIcon icon={faRemove} />
                        }
                        {params.value.user_id == localStorage.getItem("userID") &&
                            <FontAwesomeIcon icon={faCrown} />
                        }
                    </div>
                    <div style={{marginLeft: '5px', marginRight: '5px'}}>
                        {tripDetails && new Date(tripDetails.hora_saida) < new Date() && params.value.user_id != localStorage.getItem("userID") && params.value.valid === 0 && params.value.rejected === 0 &&
                            <button
                                className={"btn-primary-circle btn-error-valid"}
                                onClick={(event) => rejectUser(event, params.id, params.value.user_id)}
                            >
                                <FontAwesomeIcon icon={faRemove} />
                            </button>
                        }
                    </div>
                </div>
            ),
        },
    ];

 

    const validateUser = (event, id, user_id) => {
        event.stopPropagation();
        const currentUser = localStorage.getItem("userID");
        if (currentUser !== user_id) {
            Candidature.validate(id)
                .then(({ data }) => {
                    console.log('validateCandidatureResponse', data);
                    getTripUsers(); // Refresh trip users after validation
                }).catch(err => {
                    console.log('error on updating car:', err);
                });
        }
    };

    const rejectUser = (event, id, user_id) => {
        event.stopPropagation()
        const currentUser = localStorage.getItem("userID");
        if (currentUser !== user_id) {
            Candidature.reject(id)
                .then(({ data }) => {
                    console.log('rejectCandidatureResponse', data);
                    getTripUsers(); // Refresh trip users after validation
                }).catch(err => {
                    console.log('error on updating car:', err);
                });
        }
    }

 

    const getTripDetails = async () => {
        try {
            const { data } = await Trip.get(tripId);
            console.log('getTrip', data);
            setTripDetails(data); // Set trip details
        } catch (err) {
            if (err.response.status === 422) {
                console.log('error on getting candidatures:', err.response.data.errors);
            }
        }
    };

 

    const getTripUsers = async () => {
        try {
            const { data } = await Candidature.get(tripId);

            // Em primeiro deve vir sempre o creator
            let organized_array = []
            data.forEach(element => {
                if(element.user.id == localStorage.getItem('userID')){
                    organized_array.unshift(element)
                }else{
                    organized_array.push(element)
                }
            });
            

            const transformedData = organized_array.map(candidature => ({
                id: candidature.id,
                user: candidature.user.name,
                phone_number: candidature.user.phone_number,
                actions: { valid: candidature.valid, user_id: candidature.user.id, rejected: candidature.rejected },
            }));
            console.log('getCandidatures', transformedData);
            setTripUsers({ columns: columns, rows: transformedData });
        } catch (err) {
            if (err.response.status === 422) {
                console.log('error on getting a trip:', err.response.data.errors);
            }
        }
    };

    function handleCondutorName(name){
        let array = name.split(' ')

        return array[0] + ' ' + array[array.length - 1]
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

 

    function formatTime(dateString) {
        const date = new Date(dateString);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal details-modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>
                    &times;
                </span>
                {tripDetails == null &&
                    <div className="loading-container">
                        <TailSpin height="80" width="80" color="#0c1534" ariaLabel="loading" />
                    </div>
                }
                {tripDetails != null &&
                    <div className="row">
                        <div className="col-6 col-lg-2">
                            <div className="blue-card-small">
                                <div className="blue-card-label-small">Data de Partida</div>
                                <div className="blue-card-value-small">{formatDate(tripDetails.hora_saida)}</div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-2">
                            <div className="blue-card-small">
                                <div className="blue-card-label-small">Hora de Partida</div>
                                <div className="blue-card-value-small">{formatTime(tripDetails.hora_saida)}</div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-2">
                            <div className="blue-card-small">
                                <div className="blue-card-label-small">Data de Chegada</div>
                                <div className="blue-card-value-small">{formatDate(tripDetails.hora_chegada)}</div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-2">
                            <div className="blue-card-small">
                                <div className="blue-card-label-small">Hora de Chegada</div>
                                <div className="blue-card-value-small">{formatTime(tripDetails.hora_chegada)}</div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-2">
                            <div className="blue-card-small">
                                <div className="blue-card-label-small">Partida</div>
                                <div className="blue-card-value-small">{tripDetails.partida}</div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-2">
                            <div className="blue-card-small">
                                <div className="blue-card-label-small">Destino</div>
                                <div className="blue-card-value-small">{tripDetails.destino}</div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-2">
                            <div className="blue-card-small">
                                <div className="blue-card-label-small">Lugares</div>
                                <div className="blue-card-value-small">{tripDetails.lugares_disponiveis - tripDetails.trip_available_seats}/{tripDetails.lugares_disponiveis}</div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-2">
                            <div className="blue-card-small">
                                <div className="blue-card-label-small">KMs</div>
                                <div className="blue-card-value-small">{tripDetails.km_viagem}</div>
                            </div>
                        </div>
                        { tripDetails.car != null &&
                        <div className="col-6 col-lg-2">
                            <div className="blue-card-small">
                                <div className="blue-card-label-small">Veiculo Matrícula</div>
                                <div className="blue-card-value-small">{tripDetails.car.matricula}</div>
                            </div>
                        </div>
                        }
                        { tripDetails.car != null &&
                        <div className="col-6 col-lg-2">
                            <div className="blue-card-small">
                                <div className="blue-card-label-small">Veiculo Marca</div>
                                <div className="blue-card-value-small">{tripDetails.car.marca}</div>
                            </div>
                        </div>
                        }
                        { tripDetails.car != null &&
                        <div className="col-6 col-lg-2">
                            <div className="blue-card-small">
                                <div className="blue-card-label-small">Veiculo Modelo</div>
                                <div className="blue-card-value-small">{tripDetails.car.modelo}</div>
                            </div>
                        </div>
                        }
                        <div className="col-12 col-lg-2">
                            <div className="blue-card-small">
                                <div className="blue-card-label-small">Condutor</div>
                                <div className="blue-card-value-small">{handleCondutorName(tripDetails.user_creator.name)}</div>
                            </div>
                        </div>
                        <div className="col-12 users-datagrid">
                            {tripUsers.rows.length > 0 &&
                                <DataGrid
                                    {...tripUsers}
                                    localeText={datagridPT}
                                    autoHeight
                                    initialState={{
                                        ...tripUsers.initialState,
                                        pagination: { paginationModel: { pageSize: 25 } },
                                    }}
                                    pageSizeOptions={[5, 10, 25]}
                                    columnVisibilityModel={columnVisibility}
                                    disableSelectionOnClick
                                />
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
} 

export default DetailsModal;